import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Social from './Social';

const SubFooter = (props) => {
  const data = useStaticQuery(graphql`
    query SubFooterQuery {
      configJson {
        footer {
          copyright_text
          copyright_link
        }
      }
    }
  `);
  return (
    <div className='sub-footer'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <div className='sub-footer-inner'>
              <div style={{ display: 'flex' }}>
                <div style={{ paddingRight: '30px' }}>
                  <div>801 North Salina Street</div>
                  <div>Syracuse, NY 13208</div>
                </div>
                <div>
                  <div>Syracuse Dental Associates, PLLC</div>
                  <div>P.O. Box 11170</div>
                  <div>Syracuse, NY 13218</div>
                </div>
              </div>
              <div className='copyright'>
                <span>{data.configJson.footer.copyright_text}</span>
                {data.configJson.footer.copyright_link && (
                  <a href={data.configJson.footer.copyright_link}>Zerostatic</a>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubFooter;
